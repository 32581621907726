import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Navbar.module.css';
import logo from '../asset/images/logo.png';

function Navbar() {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    const closeMenu = () => {
        setMobileMenuOpen(false);
    };

    return (
        <header className={styles.header}>
            <div className={styles.contactInfo}>
                <div className={styles.marquee}>
                    * All Apollo Medicines Available | Call us: 
                    <a href="tel:+91 88700 69977" className={styles.customatag}>&nbsp;+91 88700 69977</a>
                    &nbsp;|
                    <a href="tel:+91 444 796 3353" className={styles.customatag}>&nbsp;+91 444 796 3353</a>
                    &nbsp;|&nbsp;Email:&nbsp;
                    <a href="mailto:contact@humanity-medical-surgicals.com" className={styles.customatag}>contact@humanity-medical-surgicals.com</a>
                </div>
            </div>
            <nav className={styles.navbar}>
                <div className={styles.navbarBrand}>
                    <NavLink to="/" className={styles.logo} onClick={closeMenu}>
                        <img src={logo} alt="Logo" className={styles.logoImage} />
                        Humanity Medical & Surgicals
                    </NavLink>
                </div>
                <div className={styles.hamburger} onClick={toggleMenu}>
                    &#9776;
                </div>
                <div
                    className={`${styles.navLinks} ${
                        isMobileMenuOpen ? styles.navLinksMobileOpen : ''
                    }`}
                >
                    {isMobileMenuOpen && (
                        <div className={styles.closeIcon} onClick={closeMenu}>
                            &times; 
                        </div>
                    )}

                    <NavLink
                        to="/"
                        className={({ isActive }) =>
                            isActive ? `${styles.navLink} ${styles.activeLink}` : styles.navLink
                        }
                        onClick={closeMenu}
                    >
                        Home
                    </NavLink>
                    <NavLink
                        to="/about"
                        className={({ isActive }) =>
                            isActive ? `${styles.navLink} ${styles.activeLink}` : styles.navLink
                        }
                        onClick={closeMenu}
                    >
                        About
                    </NavLink>
                    <NavLink
                        to="/service"
                        className={({ isActive }) =>
                            isActive ? `${styles.navLink} ${styles.activeLink}` : styles.navLink
                        }
                        onClick={closeMenu}
                    >
                        Services
                    </NavLink>
                    <NavLink
                        to="/contact"
                        className={({ isActive }) =>
                            isActive ? `${styles.navLink} ${styles.activeLink}` : styles.navLink
                        }
                        onClick={closeMenu}
                    >
                        Contact
                    </NavLink>
                </div>
            </nav>
        </header>
    );
}

export default Navbar;
