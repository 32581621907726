import React, { useState, useEffect, useRef } from "react";
import styles from "./Home.module.css";
import img1 from "../asset/images/slide1.jpg";
import {
  FaPills,
  FaHeartbeat,
  FaSyringe,
  FaTruck,
  FaStar,
  FaUsers,
  FaBox,
  FaGoogle,
} from "react-icons/fa";
import CountUp from "react-countup";

function Home() {
  const [isInView, setIsInView] = useState(false);
  const statisticsRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.unobserve(statisticsRef.current);
        }
      },
      { threshold: 0.5 }
    );

    if (statisticsRef.current) {
      observer.observe(statisticsRef.current);
    }

    return () => {
      if (statisticsRef.current) {
        // eslint-disable-next-line
        observer.unobserve(statisticsRef.current);
      }
    };
  }, []);

  return (
    <div className={styles.home}>
      <div className={styles.carousel}>
        <img src={img1} alt="Welcome to Humanity Medical & Surgicals" />
      </div>

      <div className={styles.aboutSection}>
        <h2 className={styles.aboutTitle}>
          Welcome to Humanity Medical & Surgicals
        </h2>
        <p className={styles.aboutText}>
          Located in the heart of Chennai, Humanity Medical & Surgicals is
          dedicated to providing a comprehensive range of medical and surgical
          products. Our commitment is to enhance the well-being of our community
          by offering reliable and quality healthcare solutions. Whether you
          need general medicines, specialty medicines, or surgical equipment, we
          have everything to cater to your healthcare needs.
        </p>
      </div>

      <div className={styles.statisticsSection} ref={statisticsRef}>
        <div className={styles.statCard}>
          <FaUsers className={styles.statIcon} />
          <h3>
            {isInView && (
              <CountUp start={0} end={10000} duration={3} separator="," />
            )}
            +
          </h3>
          <p>Happy Customers</p>
        </div>
        <div className={styles.statCard}>
          <FaBox className={styles.statIcon} />
          <h3>
            {isInView && (
              <CountUp start={0} end={5000} duration={3} separator="," />
            )}
            +
          </h3>
          <p>Products Delivered</p>
        </div>
        <div className={styles.statCard}>
          <FaGoogle className={styles.statIcon} />
          <h3>
            {isInView && (
              <CountUp start={0} end={50} duration={3} separator="," />
            )}
            +
          </h3>
          <p>Google Reviews</p>
        </div>
      </div>

      <div className={styles.featuredServices}>
        <h2 className={styles.sectionTitle}>Our Key Services</h2>
        <div className={styles.servicesContainer}>
          <div className={styles.serviceCard}>
            <FaPills className={styles.serviceIcon} />
            <h3>General Medicines</h3>
            <p>
              A wide range of general medicines for all age groups, ensuring
              health and wellness for everyone.
            </p>
          </div>
          <div className={styles.serviceCard}>
            <FaHeartbeat className={styles.serviceIcon} />
            <h3>Specialty Medicines</h3>
            <p>
              We provide specialty medicines, including oncology drugs, kidney
              medicine.
            </p>
          </div>
          <div className={styles.serviceCard}>
            <FaSyringe className={styles.serviceIcon} />
            <h3>Surgical Equipment</h3>
            <p>
              High-quality surgical items for healthcare providers and
              individuals to meet all surgical needs.
            </p>
          </div>
          <div className={styles.serviceCard}>
            <FaTruck className={styles.serviceIcon} />
            <h3>Free Door Delivery</h3>
            <p>
              Same-day delivery within Chennai. Fast and reliable courier
              services for your convenience.
              <br />
              Call us: +91 88700 69977{" "}
            </p>
          </div>
        </div>
      </div>

      <div className={styles.testimonialsSection}>
        <h2 className={styles.sectionTitle}>Testimonials</h2>
        <div className={styles.testimonialsContainer}>
          <div className={styles.testimonialCard}>
            <div className={styles.testimonialIcon}>
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
            </div>
            <p>
              "Excellent customer service. I choose to buy medicine from this
              pharmacy only because of his service 👍🏻"
            </p>
            <span>Aswinisrinivasan Aswinisrinivasan</span>
          </div>
          <div className={styles.testimonialCard}>
            <div className={styles.testimonialIcon}>
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
            </div>
            <p>
              "Alhamdulillah... 🤲💗I am very satisfied with this shop of my
              friend. All types of medicine are available in this shop. Everyone
              will come to this shop. Medicine services are available at the
              lowest prices in this shop. Everyone pray for my dear friend's
              shop to be good. ❤️"
            </p>
            <span>Tabib Islam Sumon</span>
          </div>

          <div className={styles.testimonialCard}>
            <div className={styles.testimonialIcon}>
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
            </div>
            <p>
              "The way of handling a customer nice to me and very good response
              to us. Keep going a way is got good reach.."
            </p>
            <span>Sathiya</span>
          </div>

          <div className={styles.testimonialCard}>
            <div className={styles.testimonialIcon}>
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
            </div>
            <p>
              "This pharmacy owner, Maani bhai is an excellent person with
              integrity, empathy, and cooperation. I am from Bangladesh, a
              foreigner in India. Owner Maani bhai not only provided me
              emergency medicines at wholesale rates, but also he helped me in
              other issues too. Like a brother from another mother... Best
              wishes for Humanity Medical and expect it to maintain its humane
              quality in providing services."
            </p>
            <span>Arafat Khan</span>
          </div>

          <div className={styles.testimonialCard}>
            <div className={styles.testimonialIcon}>
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
            </div>
            <p>
              "Good service. Very co operative all medicines are available.
              Person there are all very well behaved. U can buy medicine 💊
              there In bulk also Reasonable and genuine medicine"
            </p>
            <span>Amar chand Agarwala</span>
          </div>

          <div className={styles.testimonialCard}>
            <div className={styles.testimonialIcon}>
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
            </div>
            <p>
              "This Humanity medical is one of the best pharmacy near by Apollo
              hospital Chennai, All of staff are very helpful & cooperative,
              price also good they try to give maximum discount"
            </p>
            <span>Hasan mohammed Saleh</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
