import Axios from "../utils/Axios.interceptor";

export const getHAuth = async () => {
  return new Promise(async (resolve) => {
    try {
      const result = await Axios.get("/api/permit");
      return resolve({
        status: true,
        token: result.headers?.["x-token"] ?? null,
      });
    } catch (err) {
      return resolve({ status: false, token: null });
    }
  });
};

export const sendConntactInfo = async ({ name, phone, message, file }) => {
  return new Promise(async (resolve) => {
    try {
      const { status, token } = await getHAuth();
      if (!status) {
        return resolve({
          status: false,
          detail: "Unable To Contact. Please Try Again!!!",
        });
      }

      //build form
      const form = new FormData();
      form.append("customerName", name);
      form.append("customerPhone", phone);
      form.append("customerMessage", message);
      form.append("prescription", file);

      const { data } = await Axios.post("/api/contact", form, {
        headers: {
          authorization: token,
          "Content-Type": "multipart/form-data",
        },
      });

      return resolve({
        status: data.status ?? false,
        detail: data.status
          ? "Details Send Successfully!!!"
          : "Unable To Contact. Please Try Again!!!",
      });
    } catch (err) {
      return resolve({
        status: false,
        detail: "Unable To Contact. Please Try Again!!!",
      });
    }
  });
};
