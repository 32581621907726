import React, { useEffect, useState } from 'react';
import styles from './FloatingButton.module.css';
import { FaWhatsapp,FaFilePrescription } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';

function FloatingButton() {
    const [isAboveFooter, setIsAboveFooter] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const handleScroll = () => {
            const footerElement = document.querySelector('footer');

            if (footerElement) {
                const footerRect = footerElement.getBoundingClientRect();
                const viewportHeight = window.innerHeight;

                if (footerRect.top <= viewportHeight) {
                    setIsAboveFooter(true);
                } else {
                    setIsAboveFooter(false);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const navToContact = () =>{
        navigate('/contact');
    }

    return (
        <div>
            <a href="https://wa.me/+918870069977" className={`${styles.floatingButtonWA} ${isAboveFooter ? styles.aboveFooter : ''}`} target="_blank" rel="noopener noreferrer">
                        <FaWhatsapp />
            </a>
            <button className={`${styles.floatingButton} ${isAboveFooter ? styles.aboveFooter : ''}`} onClick={navToContact}>
                <FaFilePrescription />&nbsp;Upload Prescription
            </button>
        </div> 
    );
}

export default FloatingButton;
