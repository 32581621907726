import React from "react";
import styles from "./Services.module.css";
import { FaPills, FaSyringe, FaMedkit, FaShieldAlt, FaTruck, FaTag } from "react-icons/fa";

const Services = () => {
  return (
    <div className={styles.servicesContainer}>
      <h2 className={styles.heading}>Our Services</h2>
      <p className={styles.intro}>
        Humanity Medical & Surgicals is committed to being your trusted partner in healthcare by offering a wide range of high-quality products and services. We cater to the diverse medical needs of our customers with prompt, reliable service and a customer-centric approach. Here's an overview of the services we provide:
      </p>
      <div className={styles.servicesList}>
        <div className={styles.service}>
          <FaPills className={styles.icon} />
          <h3>General Medicines</h3>
          <p className={styles.servicePtag}>We stock a comprehensive range of general medicines to meet everyday healthcare needs. Our inventory is regularly updated to ensure that our customers have access to the latest and most effective treatments available.</p>
        </div>
        <div className={styles.service}>
          <FaSyringe className={styles.icon} />
          <h3>Vaccinations</h3>
          <p className={styles.servicePtag}>Stay protected with our variety of vaccines. We provide vaccines for both adults and children, helping to safeguard your health against various diseases.</p>
        </div>
        <div className={styles.service}>
          <FaMedkit className={styles.icon} />
          <h3>Cosmetic Products</h3>
          <p className={styles.servicePtag}>Your health and beauty are our priority. We offer a selection of cosmetic products that are safe and effective, catering to all skin types and preferences.</p>
        </div>
        <div className={styles.service}>
          <FaShieldAlt className={styles.icon} />
          <h3>Speciality Medicines</h3>
          <p className={styles.servicePtag}>We provide medicines for long-term and complicated health conditions. Our wide range of products ensures you get the right treatment for your health.</p>
        </div>
        <div className={styles.service}>
          <FaTruck className={styles.icon} />
          <h3>Surgical Equipment</h3>
          <p className={styles.servicePtag}>For healthcare professionals and home caregivers, we supply a wide range of surgical equipment. Our products meet high standards of quality and safety, ensuring reliable performance in critical applications.</p>
        </div>
        <div className={styles.service}>
          <FaMedkit className={styles.icon} />
          <h3>Oncology Medicines</h3>
          <p className={styles.servicePtag}>Cancer care requires specialized support, and we provide a range of oncology medicines to aid in treatment and recovery. Our commitment is to support patients through every step of their health journey.</p>
        </div>
        <div className={styles.service}>
          <FaTruck className={styles.icon} />
          <h3>Same-Day Delivery within Chennai</h3>
          <p className={styles.servicePtag}>We understand that timely access to medicines is crucial. Our same-day delivery service within Chennai ensures that you receive your medications and supplies as quickly as possible.</p>
        </div>
        <div className={styles.service}>
          <FaTruck className={styles.icon} />
          <h3>Courier Service to All Over India</h3>
          <p className={styles.servicePtag}>No matter where you are in India, our courier service makes it easy to access the medical supplies you need. We ensure secure and prompt delivery right to your doorstep.</p>
        </div>
        <div className={styles.service}>
          <FaShieldAlt className={styles.icon} />
          <h3>Quality Assurance on All Products</h3>
          <p className={styles.servicePtag}>Quality is at the heart of what we do. All our products come with a guarantee of quality, ensuring that you receive only the best.</p>
        </div>
        <div className={styles.service}>
          <FaTag className={styles.icon} />
          <h3>Wholesale Rates and Discounts</h3>
          <p className={styles.servicePtag}>For bulk purchases and regular customers, we offer competitive wholesale rates and discounts. Our pricing ensures that high-quality healthcare is accessible and affordable.</p>
        </div>
      </div>
      <div className={styles.whyChoose}>
        <h2>Why Choose Humanity Medical & Surgicals?</h2>
        <p>Trusted Quality: Our products are sourced from reputable manufacturers and undergo rigorous quality checks.</p>
        <p>Customer-Centric: We prioritize our customers' needs, offering personalized service and expert advice.</p>
        <p>Convenient Access: With delivery options and a wide range of products, we make healthcare accessible and hassle-free.</p>
        <p>Experienced Team: Our knowledgeable staff is always ready to assist you with product selection and healthcare advice.</p>
      </div>
    </div>
  );
};

export default Services;
