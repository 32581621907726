import axios from "axios";
import config from "../config/config";

const Axios = axios.create({
  baseURL: config.backend_url,
  maxBodyLength: Infinity,
  maxContentLength: Infinity,
  headers: { "H-Picker": config.h_picker },
  timeout: 10000,
});

export default Axios;
