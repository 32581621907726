import React, { useState } from "react";
import styles from "./Contact.module.css";
import {
  FaPhoneAlt,
  FaEnvelope,
  FaMapMarkerAlt,
  FaWhatsapp,
} from "react-icons/fa";
import { FiLoader } from "react-icons/fi";
import { IoIosSend } from "react-icons/io";
import { FaFilePrescription } from "react-icons/fa6";
import { sendConntactInfo } from "../services/contact.service";
import { toast } from "react-toastify";

const Contact = () => {
  const [file, setFile] = useState(null);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileRemove = () => {
    setFile(null);
  };

  const responseHandler = ({ status, detail }) => {
    if (status) {
      toast.success(detail);
    } else {
      toast.error(detail);
    }
    setLoader(false);
    setName("");
    setPhone("");
    setMessage("");
    setFile(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    const response = await sendConntactInfo({ name, phone, message, file });
    responseHandler(response);
  };

  const isButtonDisabled = () => {
    return !name || !phone || !file || loader;
  };

  return (
    <div className={styles.contactContainer}>
      <h2 className={styles.heading}>Contact Us</h2>

      {/* Enquiry Form Section */}
      <div className={styles.enquiryFormSection}>
        <form className={styles.contactForm} onSubmit={handleSubmit}>
          <h3 className={styles.formHeading}>Enquiry Form</h3>
          <input
            type="text"
            placeholder="Name"
            className={styles.input}
            required
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          <input
            type="tel"
            placeholder="Phone Number"
            className={styles.input}
            required
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
          />
          <textarea
            placeholder="Message"
            className={styles.textarea}
            required
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          ></textarea>
          <label className={styles.uploadButton}>
            <FaFilePrescription /> Upload Prescription
            <input
              type="file"
              accept=".jpg, .jpeg, .png"
              className={styles.fileInput}
              onChange={handleFileChange}
            />
          </label>
          {file && (
            <div className={styles.fileDetails}>
              <span>{file.name}</span>
              <button
                type="button"
                className={styles.removeButton}
                onClick={handleFileRemove}
              >
                X
              </button>
            </div>
          )}
          <button
            type="submit"
            className={styles.sendButton}
            disabled={isButtonDisabled()}
          >
            {loader ? (
              <>
                <FiLoader /> Please Wait
              </>
            ) : (
              <>
                <IoIosSend /> Send
              </>
            )}
          </button>
        </form>
      </div>

      {/* Contact Details and Map Section */}
      <div className={styles.contactDetailsSection}>
        <div className={styles.contactDetails}>
          <div className={styles.detailItem}>
            <FaWhatsapp className={styles.icon} />
            <p>
              <a href="tel:+91 88700 69977" className={styles.customatag}>
                +91 88700 69977
              </a>
            </p>
          </div>
          <div className={styles.detailItem}>
            <FaPhoneAlt className={styles.icon} />
            <p>
              <a href="tel:+91 444 796 3353" className={styles.customatag}>
                +91 444 796 3353
              </a>
            </p>
          </div>
          <div className={styles.detailItem}>
            <FaEnvelope className={styles.icon} />
            <p>
              <a
                href="mailto:contact@humanity-medical-surgicals.com"
                className={styles.customatag}
              >
                contact@humanity-medical-surgicals.com
              </a>
            </p>
          </div>
          <div className={styles.detailItem}>
            <FaMapMarkerAlt className={styles.icon} />
            <p>
              <a className={styles.customatag} href="https://rb.gy/kgrp7t">
                #6/19, Gulam Abbas Ali Khan, 7th Street, Model School Rd,
                Thousand Lights, Chennai - 600006.
              </a>
            </p>
          </div>
        </div>

        <div className={styles.map}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.6602487627183!2d80.24942377367306!3d13.057284912996808!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52672e3278fb45%3A0xa2dfa2d03a3d257d!2sHumanity%20Medical%20And%20Surgicals!5e0!3m2!1sen!2sin!4v1725087943313!5m2!1sen!2sin"
            className={styles.responsiveMap}
            allowFullScreen=""
            loading="lazy"
            title="Google Map"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
