import React from 'react';
import { FaInstagram, FaWhatsapp } from 'react-icons/fa';
import styles from './Footer.module.css';
import { MdCall, MdMail, MdLocationOn } from "react-icons/md";

function Footer() {
    return (
        <footer className={styles.footer}>
            <div className={styles.footerContainer}>
                <div className={styles.contactDetails}>
                    <p className={styles.contactPcustom}>
                        <MdCall />
                        <a href="tel:+918870069977" className={styles.customatag}>+91 88700 69977</a>
                        |
                        <a href="tel:+914447963353" className={styles.customatag}>+91 444 796 3353</a>
                    </p>
                    <p className={styles.contactPcustom}>
                        <MdMail />
                        <a href="mailto:contact@humanity-medical-surgicals.com" className={styles.customatag}>
                            contact@humanity-medical-surgicals.com
                        </a>
                    </p>
                    <p className={styles.contactPcustom}>
                        <MdLocationOn className={styles.contactIcon} />
                        <a className={styles.customatag} href='https://rb.gy/kgrp7t'>
                            #6/19, Gulam Abbas Ali Khan, 7th Street, Model School Rd, Thousand Lights, Chennai - 600006.
                        </a>
                    </p>
                </div>
                <div className={styles.socialMedia}>
                    <a href="https://wa.me/+918870069977" className={styles.socialIcon} target="_blank" rel="noopener noreferrer">
                        <FaWhatsapp />
                    </a>
                    <a href="https://www.instagram.com/humanity_medical_surgicals" className={styles.socialIcon} target="_blank" rel="noopener noreferrer">
                        <FaInstagram />
                    </a>
                    <a href="mailto:contact@humanity-medical-surgicals.com" className={styles.socialIcon} target="_blank" rel="noopener noreferrer">
                        <MdMail />
                    </a>
                </div>
            </div>
            <div className={styles.copyright}>
                <p>&copy; 2024 Humanity Medical & Surgicals. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;
