import React from 'react';
import { FaBullseye, FaHandsHelping, FaHistory, FaThumbsUp } from 'react-icons/fa';
import styles from './About.module.css';
import visaLogo from '../asset/images/visa-logo.png';
import mastercardLogo from '../asset/images/mastercard-logo.png'; 
import RupayLogo from '../asset/images/Rupay.png'; 
import upiLogo from '../asset/images/QR.png'; 

const About = () => (
    <div className={styles.aboutContainer}>
        <div className={styles.headingSection}>
            <h1 className={styles.heading}>HUMANITY MEDICAL & SURGICALS</h1>
            <p className={styles.subHeading}>Chemist | Druggist | General Merchants</p>
        </div>
        <div className={styles.aboutContent}>
            <p>
            Humanity Medical And Surgicals, where passion meets care! As a new and vibrant addition to the healthcare community, we are dedicated to serving you with a comprehensive range of medical products and services. Our commitment to humanity drives us to provide the highest quality care and support for your well-being.
            At Humanity Medical And Surgicals, we believe in building lasting relationships with our customers based on trust, empathy, and a shared commitment to health. Visit us to start your journey towards better health.
            </p>
        </div>
        <div className={styles.valuesSection}>
            <div className={styles.value}>
                <FaBullseye className={styles.icon} />
                <h3>Our Mission</h3>
                <p>To provide compassionate, accessible healthcare by offering a wide range of medical products and services. We are committed to humanity, serving with integrity and care to support your well-being.</p>
            </div>
            <div className={styles.value}>
                <FaHandsHelping className={styles.icon} />
                <h3>Our Values</h3>
                <p>Humanity, integrity, excellence, commitment and responsibility are the core values that guide us in providing trusted, empathetic care.</p>
            </div>
            <div className={styles.value}>
                <FaHistory className={styles.icon} />
                <h3>Our Story</h3>
                <p>Since April 2024, Humanity Medical And Surgicals has been dedicated to offering quality medical products with a focus on compassion and commitment, making a meaningful impact on those we serve.</p>
            </div>
            <div className={styles.value}>
                <FaThumbsUp className={styles.icon} />
                <h3>Why Choose Us?</h3>
                <p>Expert staff, a wide range of products, and commitment to customer satisfaction. Choose Humanity Medical And Surgicals for our commitment to quality, compassion and trust.</p>
            </div>
        </div>
        <div className={styles.paymentSection}>
            <h2>Payment Options</h2>
            <p>We accept various payment methods for your convenience:</p>
            <div className={styles.paymentContainer}>
                <div className={styles.paymentLeft}>
                    <div className={styles.paymentLogos}>
                        <img src={visaLogo} alt="Visa" className={styles.paymentLogo} />
                        <img src={mastercardLogo} alt="MasterCard" className={styles.paymentLogo} />
                        <img src={RupayLogo} alt="Rupay" className={styles.paymentLogo} />
                    </div>
                    <div className={styles.paymentDetails}>
                        <p><strong>Account Holder :</strong> Humanity Medical & Surgicals</p>
                        <p><strong>Account Number :</strong> 502 000 943 11235</p>
                        <p><strong>IFSC Code :</strong> HDFC0000082</p>
                    </div>
                </div>
                <div className={styles.paymentRight}>
                    <img src={upiLogo} alt="UPI" className={styles.paymentUPI} />
                </div>
            </div>
        </div>
    </div>
);

export default About;
